// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import FORM_OPERATION_BODY from '@/graphql/shared/form-operation-body';

const QUERY = gql`
  query QuickAddForm(
    $dossierId: ID!,
    $blockId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $context: String,
  ) {
    quickAddForm: dossierTypeListQuickAddFormByBlockId(
      dossierId: $dossierId,
      blockId: $blockId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      context: $context,
    ) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

const MUTATION = gql`
  mutation QuickAddForm(
    $dossierId: ID!,
    $blockId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $data: JSON,
    $context: String
  ) {
    quickAddForm: dossierTypeListQuickAddFormByBlockId(
      dossierId: $dossierId,
      blockId: $blockId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      data: $data,
      context: $context
    ) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

export { QUERY, MUTATION };
